export const notSupport = '/not-support'
export const clearhHistory = '/clear-history'
export const signin = '/signin'
export const forgotPassword = '/forgot-password'
export const signinAdmin = '/admin/signin'
export const signout = '/signout'
export const guestAnnotation = '/annotation/guestreviewer'
export const home = '/'
export const createOrder = '/orders/new'
// export const editDraftOrder = "/orders/draft/:orderId";
export const cloneOrder = '/orders/clone/:orderId'
export const orderDetail = '/orders/detail/:orderId'
export const attachmentDetail =
  '/orders/detail/:orderId/attachments/:attachmentId'
export const versionDetail =
  '/orders/detail/:orderId/attachments/:attachmentId/versions/:versionId'
export const teamMember = '/team-members'
export const editTeamMember = '/team-members/:memberId'
export const generalSetting = '/settings'
export const paymentMethod = '/billing/payments'
export const editPaymentMethod = '/billing/payments/:paymentId'
export const invoices = '/billing/invoices'
export const invoiceDetail = '/billing/invoices/:invoiceId'
export const cart = '/checkout/cart'
export const checkoutProcess = '/checkout/process'
export const checkoutProcessInvoice = '/checkout/process/invoice/:invoiceId'
export const manageProfile = '/profile'
export const paygCustomerInfo = '/payg/list'
export const manageService = '/membership/service'
export const managePackage = '/membership/package'
export const manageStorage = '/membership/storage'
export const manageAllocatedHours = '/membership/allocated-hours'
export const createCustomer = '/customer/new'
export const customerDetail = '/customer/:customerId'
export const archivedOrder = '/archived-order'
export const myImages = '/my-images'
export const impersonateCustomer = '/impersonate-customer'
export const retail = '/retail'
export const orderListView = '/order-list'
export const payg = '/payg'
export const cartCheckout = '/cart/checkout'
export const workflowAndRoles = '/workflow-and-roles'
export const createWorkflowAndRoles = '/workflow-and-roles/create'
export const editWorkflowAndRoles = '/workflow-and-roles/:workflowId'
export const oldKanban = '/old-kanban'
